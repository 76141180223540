.microphone-container {
  background-color: #ebebeb;
  box-shadow: 0 2px 5px #bebebe;
  border-radius: 20px;
  color: #000;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  -webkit-tap-highlight-color: transparent;
  margin: 10px;
  font-size: 50px;
}
.container {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
/* .transcription-audio {
  padding: 20px; 
  margin: 20px auto;
  padding: 10px;
  border: 2px solid #333;
  text-align: center;
  box-shadow: #dadada 10px 5px 5px;
} */
.recordings{
  display: flex;
  flex-direction: column;
  gap: 20px;
}